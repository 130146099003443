import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { Form, Button, FieldTextInput, NamedLink } from '../../components';

import css from './PasswordRecoveryForm.css';

const PasswordRecoveryForm = props => {
    const [submittedValues, setSubmittedValues] = useState({});
    return (
        <FinalForm
            {...props}
            render={fieldRenderProps => {
                const {
                    rootClassName,
                    className,
                    formId,
                    handleSubmit,
                    pristine,
                    initialValues,
                    inProgress,
                    recoveryError,
                    values,
                } = fieldRenderProps;

                const emailRequired = validators.required('Dieses Feld wird benötigt');
                const emailValid = validators.emailFormatValid(
                    'Gib eine gültige E-Mail-Adresse an'
                );

                // In case a given email is not found, pass a custom error message
                // to be rendered with the input component
                const customErrorText = isPasswordRecoveryEmailNotFoundError(recoveryError)
                    ? 'Hmm, wir konnten kein Konto mit dieser E-Mail-Adresse finden. Bitte überprüfe die E-Mai Adresse und versuche es nochmals.'
                    : null;
                const initialEmail = initialValues ? initialValues.email : null;
                const emailTouched = values.email !== submittedValues.email;

                const classes = classNames(rootClassName || css.root, className);
                const submitInProgress = inProgress;
                const submittedOnce = Object.keys(submittedValues).length > 0;
                const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
                const submitDisabled =
                    (pristine && !initialEmail) || submitInProgress || pristineSinceLastSubmit;

                return (
                    <Form
                        className={classes}
                        onSubmit={e => {
                            setSubmittedValues(values);
                            handleSubmit(e);
                        }}
                    >
                        <FieldTextInput
                            className={css.email}
                            type="email"
                            id={formId ? `${formId}.email` : 'email'}
                            name="email"
                            autoComplete="email"
                            // label={emailLabel}
                            placeholder="E-Mail-Adresse"
                            validate={validators.composeValidators(emailRequired, emailValid)}
                            customErrorText={emailTouched ? null : customErrorText}
                        />

                        <div className={css.bottomWrapper}>
                            <h4 className={css.bottomWrapperText}>
                                <span className={css.modalHelperText}>
                                    Passwort doch noch eingefallen?
                                </span>{' '}
                                <NamedLink name="LoginPage" className={css.modalHelperLink}>
                                    Anmelden
                                </NamedLink>
                            </h4>

                            <Button
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                            >
                                Link anfordern
                            </Button>
                        </div>
                    </Form>
                );
            }}
        />
    );
};

export default PasswordRecoveryForm;
