import React from 'react';

export function NotSubscribedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M6.580 0.706 C 5.890 0.794,5.323 1.253,5.088 1.914 C 5.027 2.084,5.020 2.860,5.009 11.200 C 5.001 17.343,5.010 20.404,5.038 20.620 C 5.141 21.410,5.706 21.996,6.494 22.131 C 6.833 22.189,7.252 22.123,7.538 21.967 C 7.649 21.907,8.658 21.221,9.780 20.444 C 10.902 19.666,11.861 19.011,11.910 18.988 C 11.972 18.960,12.028 18.960,12.090 18.988 C 12.139 19.011,13.098 19.666,14.220 20.444 C 15.342 21.221,16.351 21.907,16.462 21.967 C 16.748 22.123,17.167 22.189,17.506 22.131 C 18.291 21.996,18.859 21.407,18.962 20.620 C 18.990 20.404,18.999 17.350,18.991 11.200 C 18.977 1.075,19.003 1.968,18.708 1.512 C 18.481 1.163,18.155 0.905,17.766 0.768 C 17.597 0.708,17.072 0.702,12.140 0.697 C 9.148 0.693,6.646 0.698,6.580 0.706 M17.407 2.266 C 17.477 2.330,17.480 2.667,17.480 11.419 C 17.480 19.011,17.471 20.517,17.426 20.579 C 17.298 20.754,17.220 20.709,15.101 19.240 C 13.979 18.463,12.980 17.778,12.881 17.718 C 12.333 17.384,11.605 17.404,11.035 17.769 C 10.913 17.847,9.923 18.528,8.836 19.282 C 6.782 20.707,6.702 20.753,6.574 20.579 C 6.529 20.517,6.520 19.012,6.520 11.426 C 6.520 3.033,6.525 2.341,6.586 2.273 C 6.650 2.203,6.870 2.200,11.993 2.200 C 16.906 2.200,17.340 2.205,17.407 2.266 "
                stroke="none"
                fillRule="evenodd"
                fill="#1A2B49"
            />
        </svg>
    );
}
export function SubscribedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#8F2593"
                strokeWidth="1.5"
                d="M17.345 3H7.414A.414.414 0 007 3.414V20.21c0 .334.375.53.65.34l4.494-3.111a.414.414 0 01.47 0l4.495 3.111c.275.19.65-.006.65-.34V3.414A.414.414 0 0017.345 3z"
            ></path>
        </svg>
    );
}
