import React from 'react';
import { trimDisplayNameLastWord } from '../../util/text';
import { userDisplayNameAsString } from '../../util/data';
import {
    txIsEnquired,
    txIsExpired,
    txIsDeclined,
    txIsAccepted,
    getUserTxRole,
    txLastTransition,
} from '../../util/transaction';

const asteriskNumber = 5;

// Helper function to get display names for different roles
export const displayNames = (currentUser, currentProvider, currentCustomer, intl, Component) => {
    const authorDisplayName = <Component user={currentProvider} intl={intl} />;
    const customerDisplayName = <Component user={currentCustomer} intl={intl} />;

    let otherUserDisplayName = '';
    let otherUserDisplayNameString = '';

    const currentUserIsCustomer =
        currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
    const currentUserIsProvider =
        currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

    if (currentUserIsCustomer) {
        otherUserDisplayName = authorDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
    } else if (currentUserIsProvider) {
        otherUserDisplayName = customerDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
    }
    otherUserDisplayNameString = trimDisplayNameLastWord(otherUserDisplayNameString);

    return {
        authorDisplayName,
        customerDisplayName,
        otherUserDisplayName,
        otherUserDisplayNameString,
    };
};

export const addAsterisk = (message, regExp) => {
    let asteriskMessage = '';
    const numbers = message.match(new RegExp(regExp, 'g'));
    numbers
        .map(n => n.trim())
        .forEach(number => {
            const index = message.indexOf(number);
            if (~index) {
                const numberOfSpaces = number.split(' ').length;
                const asterisk = Array.from(
                    { length: asteriskNumber + numberOfSpaces },
                    x => '*'
                ).join('');
                const messageToAdd = asteriskMessage ? asteriskMessage : message;

                asteriskMessage =
                    messageToAdd.slice(0, index) +
                    asterisk +
                    messageToAdd.slice(index + asteriskNumber + numberOfSpaces);
            }
        });
    return asteriskMessage;
};

export const messageChecks = [/[\d\s]{8,}/, /[\d\w.]+@[\w\d]+.[\w\d]+/];

export const resolveStateDataFn = ({ currentTransaction: tx, isProvider, currentUser }) => {
    if (txIsEnquired(tx)) {
        const ownRole = getUserTxRole(currentUser.id, tx);
        const transition = tx.attributes.transitions.find(
            ({ transition }) => transition === txLastTransition(tx)
        );
        const isOwnTransition =
            transition.by === ownRole ||
            (transition.sender && transition.sender.userRole === ownRole);

        return {
            showAcceptDeclineRequestButtons: !isOwnTransition,
            showMessageNotification: isOwnTransition,
            showQuestionsSet: !isOwnTransition,
            hideSendMessagePanel: true,

            showStatusPanel: true,
            enquiryStatusIsActive: true,
        };
    } else if (txIsExpired(tx)) {
        return {
            showAcceptDeclineRequestButtons: false,
            showQuestionsSet: false,
            hideSendMessagePanel: true,

            showStatusPanel: true,
            enquiryStatusIsActive: true,
        };
    } else if (txIsDeclined(tx)) {
        return {
            showAcceptDeclineRequestButtons: false,
            showQuestionsSet: false,
            hideSendMessagePanel: true,
            showStatusPanel: true,
            enquiryStatusIsActive: true,
        };
    } else if (txIsAccepted(tx)) {
        return {
            showAcceptDeclineRequestButtons: false,
            showStatusPanel: true,
            enquiryStatusIsFinished: true,
            subscriptionStatusIsActive: true,
            hideFooter: isProvider,
        };
    } else {
        return { headingState: 'unknown' };
    }
};
