import React from 'react';
import { func } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { getUserTxRole } from '../../util/transaction';
import { ensureTransaction } from '../../util/data';
import { TransitionMessageContent } from '../../components';
import { resolveTransitionMessage } from './resolveTransitionMessage';

const TransitionMessage = props => {
    const { transition, transaction, currentUser } = props;
    const currentTransaction = ensureTransaction(transaction);
    // const customer = currentTransaction.customer;
    // const provider = currentTransaction.provider;

    const ownRole = getUserTxRole(currentUser.id, currentTransaction);

    const transitionMessage = resolveTransitionMessage({
        currentTransaction,
        ownRole,
        ...props,
    });

    return (
        <TransitionMessageContent transition={transition} transitionMessage={transitionMessage} />
    );
};

TransitionMessage.propTypes = {
    transition: propTypes.transition.isRequired,
    transaction: propTypes.transaction.isRequired,
    currentUser: propTypes.currentUser.isRequired,
    intl: intlShape.isRequired,
    onOpenReviewModal: func.isRequired,
};

export default TransitionMessage;
