import React from 'react';
import { Button, NamedRedirect, PaymentIconsBar } from '../../../components';

import { parse } from '../../../util/urlHelpers';
import css from './EditListingPricingPageCheckout.css';

const formatPrice = price => (Math.round(price) / 100).toFixed(2);

export default ({ handlePackagesPurchase, displayPackages, search, loading, ready }) => {
    const { packages: packagesSearchString } = parse(search) || {};

    if (!packagesSearchString) {
        return <NamedRedirect name="ManageListingsPage" />;
    }
    const packages = packagesSearchString.split(',');
    const packagesToPurchase = displayPackages.filter(({ id }) => packages.includes(id));

    return (
        <section>
            <h4 className={css.checkoutSubTitle}>Übersicht</h4>
            <div className={css.packageCheckoutHolder}>
                {packagesToPurchase.map(({ title, id, price, currency }) => (
                    <div className={css.packageCheckoutInfo} key={id}>
                        <p>1x {title}</p>
                        <p>
                            {formatPrice(price)} {currency}
                        </p>
                    </div>
                ))}
                <aside className={css.packageCheckoutTotal}>
                    <p>Gesamttotal</p>
                    <p>
                        {formatPrice(packagesToPurchase.reduce((acc, { price }) => acc + price, 0))}{' '}
                        {packagesToPurchase[0] && packagesToPurchase[0].currency}
                    </p>
                </aside>
            </div>
            <PaymentIconsBar rootClassName={css.paymentMethodsInfo} />
            <footer className={css.checkoutFooter}>
                <Button
                    ready={ready}
                    inProgress={loading}
                    disabled={loading || ready}
                    onClick={handlePackagesPurchase}
                >
                    Bezahlen
                </Button>
            </footer>
        </section>
    );
};
