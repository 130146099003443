import React from 'react';
import { Link } from 'react-router-dom';
import { NamedLink } from '../../../../components';
import css from './SectionFAQ.css';

export const questions = {
    'find-listing-rider': [
        {
            heading: 'Was ist HorseDeal?',
            description: (
                <>
                    <p>
                        Mit HorseDeal, der Sharing-Plattform für Pferde, erhältst Du als
                        Pferdebesitzer:in Zugang zu einer grossen Datenbank von qualifizierten und
                        verfügbaren Pferdemenschen, die sich sich zeitlich und finanziell an deinem
                        Pferd beteiligen.
                    </p>
                    <p>
                        Egal, ob eine kurzfristige Aushilfe, z. B. bei Abwesenheit, Krankheit,
                        Schwangerschaft, oder eine langfristige Beteiligung, z. B. um dein Pferd
                        regelmässig zu bewegen oder zu trainieren.
                    </p>
                    <p>
                        Pferdemenschen finden in ihrer Nähe eine vielfältige Auswahl an Pferden, die
                        zu ihren Kenntnissen passen. So kommen sie zu ihrer Dosis Pferdeluft, ganz
                        ohne ein eigenes Pferd zu besitzen.
                    </p>
                </>
            ),
        },
        {
            heading: 'Was kostet HorseDeal?',
            description: (
                <>
                    <p>
                        Die Mitgliedschaft und das Inserieren als Pferdebesitzer:in bei HorseDeal
                        sind kostenlos.
                    </p>
                    <p>
                        Wir bieten dir als Pferdebesitzer:in die Möglichkeit, Premium-Funktionen zu
                        erwerben, die es dir leichter machen, neue Pferdemenschen in deiner Nähe zu
                        finden, dein Inserat anzupassen und mehr Sichtbarkeit für dieses zu
                        erhalten. Die Premium-Funktionen können nach der Registrierung für
                        unterschiedliche Zeiträume erworben werden.
                    </p>
                </>
            ),
        },
        {
            heading: 'Wie kann ich mich registrieren?',
            description: (
                <>
                    <p>
                        Hinweis: Du kannst auch ohne ein Konto bereits damit starten, dein Pferd
                        kostenlos zu inserieren:{' '}
                        <NamedLink name="NewListingPage">Pferd kostenlos inserieren</NamedLink>
                    </p>
                    <p>
                        Um als Pferdebesitzer:in ein kostenloses Konto bei HorseDeal zu erstellen,
                        besuche die{' '}
                        <NamedLink name="SignupHorseowner">Registrierungsseite</NamedLink>.
                    </p>
                    <p>Die Voraussetzungen für eine Registrierung sind folgende:</p>
                    <ul className={css.faqList}>
                        <li>Du bist mindestens 12 Jahre alt</li>
                        <li>Du hast noch kein Konto mit der gleichen E-Mail Adresse</li>
                        <li>Du verfügst über kein bestehendes, blockiertes Konto</li>
                    </ul>
                    <p>
                        Im nächsten Schritt kannst Du für deine Registrierung aus den folgenden drei
                        Optionen auswählen:
                    </p>
                    <ul className={css.faqList}>
                        <li>Registrierung mit Google</li>
                        <li>Registrierung mit Facebook</li>
                        <li>Registrierung mit E-Mail-Adresse und Passwort</li>
                    </ul>
                    <p>
                        Eine detaillierte Anleitung mit Bildern, findest Du in diesem{' '}
                        <Link to="https://support.horsedeal.com/hc/de/articles/18390753647378">
                            FAQ-Artikel
                        </Link>
                        .
                    </p>
                </>
            ),
        },
        {
            heading: 'Wie kann ich Hilfe erhalten?',
            description: (
                <>
                    <p>
                        Benötigst Du Hilfe bei der Erstellung deines Kontos oder dem Inserieren? Wir
                        sind für dich da und helfen dir sehr gerne weiter.
                    </p>
                    <p>
                        Um uns zu kontaktieren, besuche die <a href="/karriere">Kontakt</a> und
                        wähle aus einer der folgenden Kontaktmöglichkeiten:
                    </p>
                    <ul className={css.faqList}>
                        <li>Hotline</li>
                        <li>Kontaktformular</li>
                        <li>Beratungsgespräch</li>
                    </ul>
                    <p>
                        Gerne steht dir unser Support-Team während unseren regulären Öffnungszeiten
                        zur Verfügung.
                    </p>
                    <h4 className={css.faqSubHeading}>Unsere Öffnungszeiten</h4>
                    <div>Montag: 08:00 - 18:00 Uhr</div>
                    <div>Dienstag: 08:00 - 18:00 Uhr</div>
                    <div>Mittwoch: 08:00 - 18:00 Uhr</div>
                    <div>Donnerstag: 08:00 - 18:00 Uhr</div>
                    <div>Freitag: 08:00 - 18:00 Uhr</div>
                </>
            ),
        },
        {
            heading: 'Gibt es eine Mobile-App?',
            description: (
                <>
                    <p>Ja klar! Wir bieten eine App für Android und iOS an. 🙌🏻</p>

                    <h4 className={css.faqSubHeading}>HorseDeal-App für Android</h4>
                    <p>
                        Du kannst unsere App nahtlos auf dein Android-Smartphone herunterladen,
                        indem Du im Playstore nach dem Begriff "HorseDeal" suchst oder den folgenden
                        Link verwendest: 
                    </p>
                    <br></br>
                    <p>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.horsedeal.www"
                            target="_blank"
                        >
                            HorseDeal für Android
                        </a>
                    </p>

                    <h4 className={css.faqSubHeading}>HorseDeal-App für iOS</h4>
                    <p>
                        Du kannst unsere App nahtlos auf dein iPhone herunterladen, indem Du im App
                        Store nach dem Begriff "HorseDeal" suchst oder den folgenden Link
                        verwendest:
                    </p>
                    <br></br>
                    <p>
                        <a
                            href="https://apps.apple.com/ch/app/horsedeal/id6479301025"
                            target="_blank"
                        >
                            HorseDeal für iOS
                        </a>
                    </p>
                </>
            ),
        },
    ],
    'find-horse': [
        {
            heading: 'Was ist HorseDeal?',
            description: (
                <>
                    <p>
                        Mit HorseDeal, der Sharing-Plattform für Pferde, erhalten
                        Pferdebesitzer:innen Zugang zu einer grossen Datenbank von qualifizierten
                        und verfügbaren Pferdemenschen, die sich zeitlich und finanziell an ihrem
                        Pferd beteiligen.
                    </p>
                    <p>
                        Egal, ob eine kurzfristige Aushilfe, z. B. bei Abwesenheit, Krankheit,
                        Schwangerschaft, oder eine langfristige Beteiligung, z. B. um ein Pferd
                        regelmässig zu bewegen oder zu trainieren.
                    </p>
                    <p>
                        Du als Pferdemensch findest in deiner Nähe eine vielfältige Auswahl an
                        Pferden, die zu deinen Kenntnissen passen. So kommst Du zu deiner Dosis
                        Pferdeluft, ganz ohne ein eigenes Pferd zu besitzen.
                    </p>
                </>
            ),
        },
        {
            heading: 'Was kostet HorseDeal?',
            description: (
                <>
                    <p>Die Mitgliedschaft als Pferdemensch bei HorseDeal ist kostenlos.</p>
                    <p>
                        Wir bieten dir als Mitglied die Möglichkeit, Premium-Funktionen zu erwerben,
                        die es dir leichter machen, neue Pferde in deiner Nähe zu finden, das eigene
                        Profil anzupassen und mehr Sichtbarkeit zu erhalten. Die Premium-Funktionen
                        können nach der Registrierung für unterschiedliche Zeiträume erworben
                        werden.
                    </p>
                </>
            ),
        },
        {
            heading: 'Wie kann ich mich registrieren?',
            description: (
                <>
                    <p>
                        Um als Reiter:in, Spazierer:in oder Pfleger:in ein kostenloses Konto bei
                        HorseDeal zu erstellen, besuche die Registrierungsseite.
                    </p>
                    <p>Die Voraussetzungen für eine Registrierung sind folgende: .</p>
                    <ul className={css.faqList}>
                        <li>Du bist mindestens 12 Jahre alt</li>
                        <li>Du hast noch kein Konto mit der gleichen E-Mail Adresse</li>
                        <li>Du verfügst über kein bestehendes, blockiertes Konto</li>
                    </ul>
                    <p>
                        Im nächsten Schritt kannst Du für deine Registrierung aus den folgenden drei
                        Optionen auswählen:
                    </p>
                    <ul className={css.faqList}>
                        <li>Registrierung mit Google</li>
                        <li>Registrierung mit Facebook</li>
                        <li>Registrierung mit E-Mail-Adresse und Passwort</li>
                    </ul>
                    <p>
                        Eine detaillierte Anleitung mit Bildern, findest Du in diesem{' '}
                        <Link to="https://support.horsedeal.com/hc/de/articles/18390753647378">
                            FAQ-Artikel
                        </Link>
                        .
                    </p>
                </>
            ),
        },
        {
            heading: 'Wie kann ich Hilfe erhalten?',
            description: (
                <>
                    <p>
                        Benötigst Du Hilfe bei der Erstellung deines Kontos oder dem Inserieren? Wir
                        sind für dich da und helfen dir sehr gerne weiter.
                    </p>
                    <p>
                        Um uns zu kontaktieren, besuche die <a href="/karriere">Kontakt</a> und
                        wähle aus einer der folgenden Kontaktmöglichkeiten:
                    </p>
                    <ul className={css.faqList}>
                        <li>Hotline</li>
                        <li>Kontaktformular</li>
                        <li>Beratungsgespräch</li>
                    </ul>
                    <p>
                        Gerne steht dir unser Support-Team während unseren regulären Öffnungszeiten
                        zur Verfügung.
                    </p>
                    <h4 className={css.faqSubHeading}>Unsere Öffnungszeiten</h4>
                    <div>Montag: 08:00 - 18:00 Uhr</div>
                    <div>Dienstag: 08:00 - 18:00 Uhr</div>
                    <div>Mittwoch: 08:00 - 18:00 Uhr</div>
                    <div>Donnerstag: 08:00 - 18:00 Uhr</div>
                    <div>Freitag: 08:00 - 18:00 Uhr</div>
                </>
            ),
        },
        {
            heading: 'Gibt es eine Mobile-App?',
            description: (
                <>
                    <p>Ja klar! Wir bieten eine App für Android und iOS an. 🙌🏻</p>

                    <h4 className={css.faqSubHeading}>HorseDeal-App für Android</h4>
                    <p>
                        Du kannst unsere App nahtlos auf dein Android-Smartphone herunterladen,
                        indem Du im Playstore nach dem Begriff "HorseDeal" suchst oder den folgenden
                        Link verwendest: 
                    </p>
                    <br></br>
                    <p>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.horsedeal.www"
                            target="_blank"
                        >
                            HorseDeal für Android
                        </a>
                    </p>

                    <h4 className={css.faqSubHeading}>HorseDeal-App für iOS</h4>
                    <p>
                        Du kannst unsere App nahtlos auf dein iPhone herunterladen, indem Du im App
                        Store nach dem Begriff "HorseDeal" suchst oder den folgenden Link
                        verwendest:
                    </p>
                    <br></br>
                    <p>
                        <a
                            href="https://apps.apple.com/ch/app/horsedeal/id6479301025"
                            target="_blank"
                        >
                            HorseDeal für iOS
                        </a>
                    </p>
                </>
            ),
        },
    ],
    landing: [
        {
            heading: 'Was ist HorseDeal?',
            description: (
                <>
                    <p>
                        Mit HorseDeal, der Sharing-Plattform für Pferde, erhältst Du als
                        Pferdebesitzer:in Zugang zu einer grossen Datenbank von qualifizierten und
                        verfügbaren Pferdemenschen, die sich sich zeitlich und finanziell an deinem
                        Pferd beteiligen.
                    </p>
                    <p>
                        Egal, ob eine kurzfristige Aushilfe, z. B. bei Abwesenheit, Krankheit,
                        Schwangerschaft, oder eine langfristige Beteiligung, z. B. um dein Pferd
                        regelmässig zu bewegen oder zu trainieren.
                    </p>
                    <p>
                        Als Pferdemensch findest Du in deiner Nähe eine vielfältige Auswahl an
                        Pferden, die zu deinen Kenntnissen passen. So kommst Du zu deiner Dosis
                        Pferdeluft, ganz ohne ein eigenes Pferd zu besitzen.
                    </p>
                </>
            ),
        },
        {
            heading: 'Wer kann HorseDeal nutzen?',
            description: (
                <>
                    <p>
                        Ein eigenes Pferd zu besitzen bedeutet eine grosse Verantwortung und eine
                        hohe Investition von Zeit und Geld über mehrere Jahre - deshalb haben wir
                        HorseDeal entwickelt und richten uns mit unserer Sharing-Plattform für
                        Pferde gezielt an Pferdemenschen d.h.{' '}
                        <NamedLink name="ReitbeteiligungFindenPage">Reiter:innen</NamedLink>,{' '}
                        <NamedLink name="SpazierbeteiligungFindenPage">Spazierer:innen</NamedLink>{' '}
                        und <NamedLink name="PflegebeteiligungFindenPage">Pfleger:innen</NamedLink>,
                        sowie Pferdebesitzer:innen.
                    </p>
                    <p>
                        Dank HorseDeal finden Pferdebesitzer:innen, die aus beruflichen,
                        gesundheitlichen oder anderen Gründen nicht täglich ihr Pferd bewegen
                        können, eine passende Aushilfe, die dem Pferd die Bewegung, Pflege und Liebe
                        gibt, die es braucht.
                    </p>
                    <p>
                        Pferdemenschen erhalten einen direkten Zugang zum Pferdesport und müssen
                        sich kein eigenes Pferd anschaffen. Pferdemensch und Pferdebesitzer:in
                        teilen sich viele Aufgaben rund um das Pferd und häufig auch die Kosten für
                        Futter, Stall, Tierarzt, Versicherung etc. durch eine sogenannte
                        Kostenbeteiligung.
                    </p>
                </>
            ),
        },
        {
            heading: 'Was sind die Vorteile?',
            description: (
                <>
                    <h4 className={css.faqSubHeading}>Grosse Reiter-Datenbank</h4>
                    <p>
                        Seit unserem Start im Jahr 2020 haben wir mit HorseDeal für rund 2’500
                        Pferdebesitzer:innen erfolgreich Aushilfen fürs Reiten, Spazieren und
                        Pflegen ihrer Pferde gefunden. Dank der grossen Reiter-Datenbank findest Du
                        als Pferdebesitzer:in schnell qualifizierte und verfügbare Pferdemenschen
                        für eine kurzfristige Aushilfe oder eine langfristige Beteiligung.
                    </p>

                    <h4 className={css.faqSubHeading}>Aussagekräftige Profile</h4>
                    <p>
                        Als Pferdemensch kannst Du bei HorseDeal ein aussagekräftiges Profil
                        erstellen und mit Details zu deinen Kenntnissen ergänzen, wie z. B.
                        Disziplinen, Niveau, Qualifikationen, Auszeichnungen und Ausbildungen.
                        Fotos, Videos und Empfehlungen von früheren Pferdebesitzer:innen oder
                        Reitlehrer:innen machen das Profil vollständig und erhöhen die Chance,
                        schnell in den Kontakt mit Pferdebesitzer:innen zu kommen. Pferdemenschen
                        geben in ihrem Profil an, ob sie derzeit verfügbar sind und nach einer
                        kurzen Aushilfe oder einer langfristigen Pflege-, Spazier- oder
                        Reitbeteiligungen suchen.
                    </p>

                    <h4 className={css.faqSubHeading}>Matching-Algorithmus</h4>
                    <p>
                        Das automatisierte Matching zeigt sofort, wie gut ein Pferd zu den
                        Kenntnissen eines Pferdemenschen passt. Auch Du als Pferdebesitzer:in kannst
                        aktiv Pferdemenschen für dein Pferd anfragen und auf einen Blick einsehen,
                        ob diese den Anforderungen entsprechen.
                    </p>
                </>
            ),
        },
        {
            heading: 'Was kostet HorseDeal?',
            description: (
                <>
                    <p>Die Mitgliedschaft und das Inserieren bei HorseDeal sind kostenlos.</p>
                    <p>
                        Wir bieten dir als Mitglied die Möglichkeit, Premium-Funktionen zu erwerben,
                        die es dir leichter machen, neue Pferdemenschen oder Pferde in deiner Nähe
                        zu finden, das eigene Profil oder Inserat anzupassen und mehr Sichtbarkeit
                        zu erhalten. Die Premium-Funktionen können nach der Registrierung für
                        unterschiedliche Zeiträume erworben werden.
                    </p>
                </>
            ),
        },
        {
            heading: 'Gibt es eine Mobile-App?',
            description: (
                <>
                    <p>Ja klar! Wir bieten eine App für Android und iOS an. 🙌🏻</p>

                    <h4 className={css.faqSubHeading}>HorseDeal-App für Android</h4>
                    <p>
                        Du kannst unsere App nahtlos auf dein Android-Smartphone herunterladen,
                        indem Du im Playstore nach dem Begriff "HorseDeal" suchst oder den folgenden
                        Link verwendest: 
                    </p>
                    <br></br>
                    <p>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.horsedeal.www"
                            target="_blank"
                        >
                            HorseDeal für Android
                        </a>
                    </p>

                    <h4 className={css.faqSubHeading}>HorseDeal-App für iOS</h4>
                    <p>
                        Du kannst unsere App nahtlos auf dein iPhone herunterladen, indem Du im App
                        Store nach dem Begriff "HorseDeal" suchst oder den folgenden Link
                        verwendest:
                    </p>
                    <br></br>
                    <p>
                        <a
                            href="https://apps.apple.com/ch/app/horsedeal/id6479301025"
                            target="_blank"
                        >
                            HorseDeal für iOS
                        </a>
                    </p>
                </>
            ),
        },
    ],
};
