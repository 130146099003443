import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { isChangePasswordWrongPassword } from '../../util/errors';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './EditUserData.css';

const RESET_TIMEOUT = 800;

class PasswordChangeFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { showResetPasswordMessage: false };
        this.resetTimeoutId = null;
        this.submittedValues = {};
    }

    componentWillUnmount() {
        window.clearTimeout(this.resetTimeoutId);
    }

    render() {
        return (
            <FinalForm
                {...this.props}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        changePasswordError,
                        currentUser,
                        handleSubmit,
                        inProgress,
                        intl,
                        invalid,
                        ready,
                        form,
                        values,
                        setModalVisibility,
                        setModalForm,
                    } = fieldRenderProps;

                    const user = ensureCurrentUser(currentUser);

                    if (!user.id) {
                        return null;
                    }

                    // New password
                    const newPasswordLabel = intl.formatMessage({
                        id: 'PasswordChangeForm.newPasswordLabel',
                    });

                    const newPasswordRequiredMessage = intl.formatMessage({
                        id: 'PasswordChangeForm.newPasswordRequired',
                    });
                    const newPasswordRequired = validators.requiredStringNoTrim(
                        newPasswordRequiredMessage
                    );

                    const passwordMinLengthMessage = intl.formatMessage(
                        {
                            id: 'PasswordChangeForm.passwordTooShort',
                        },
                        {
                            minLength: validators.PASSWORD_MIN_LENGTH,
                        }
                    );
                    const passwordMaxLengthMessage = intl.formatMessage(
                        {
                            id: 'PasswordChangeForm.passwordTooLong',
                        },
                        {
                            maxLength: validators.PASSWORD_MAX_LENGTH,
                        }
                    );

                    const passwordMinLength = validators.minLength(
                        passwordMinLengthMessage,
                        validators.PASSWORD_MIN_LENGTH
                    );
                    const passwordMaxLength = validators.maxLength(
                        passwordMaxLengthMessage,
                        validators.PASSWORD_MAX_LENGTH
                    );

                    const passwordRequiredMessage = intl.formatMessage({
                        id: 'PasswordChangeForm.passwordRequired',
                    });

                    const passwordRequired = validators.requiredStringNoTrim(
                        passwordRequiredMessage
                    );

                    const passwordFailedMessage = intl.formatMessage({
                        id: 'PasswordChangeForm.passwordFailed',
                    });
                    const passwordTouched =
                        this.submittedValues.currentPassword !== values.currentPassword;
                    const passwordErrorText = isChangePasswordWrongPassword(changePasswordError)
                        ? passwordFailedMessage
                        : null;

                    const genericFailure =
                        changePasswordError && !passwordErrorText ? (
                            <span className={css.error}>
                                <FormattedMessage id="PasswordChangeForm.genericFailure" />
                            </span>
                        ) : null;

                    const submittedOnce = Object.keys(this.submittedValues).length > 0;
                    const pristineSinceLastSubmit =
                        submittedOnce && isEqual(values, this.submittedValues);
                    const classes = classNames(rootClassName || css.root, className);
                    const submitDisabled = invalid || pristineSinceLastSubmit || inProgress;

                    const passwordsDoNotMatch = validators.passwordsDoNotMatch(
                        'Die Passwörter stimmen nicht überein.',
                        { finalForm: form }
                    );

                    return (
                        <Form
                            className={classes}
                            onSubmit={e => {
                                this.submittedValues = values;
                                handleSubmit(e)
                                    .then(() => {
                                        this.resetTimeoutId = window.setTimeout(() => {
                                            form.reset();
                                            setModalVisibility(false);
                                            setModalForm(null);
                                        }, RESET_TIMEOUT);
                                    })
                                    .catch(() => {
                                        // Error is handled in duck file already.
                                    });
                            }}
                        >
                            <h3 className={css.title}>
                                <FormattedMessage id="PasswordChangeForm.confirmChangesTitle" />
                            </h3>
                            <div className={css.editSection}>
                                <FieldTextInput
                                    className={css.password}
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    autoComplete="current-password"
                                    placeholder="Aktuelles Passwort"
                                    validate={validators.composeValidators(
                                        passwordRequired,
                                        passwordMinLength,
                                        passwordMaxLength
                                    )}
                                    customErrorText={passwordTouched ? null : passwordErrorText}
                                />
                                <FieldTextInput
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    label={newPasswordLabel}
                                    placeholder="Neues Passwort"
                                    validate={validators.composeValidators(
                                        newPasswordRequired,
                                        passwordMinLength,
                                        passwordMaxLength
                                    )}
                                />
                                <FieldTextInput
                                    type="password"
                                    id="newPasswordConfirmation"
                                    name="newPasswordConfirmation"
                                    autoComplete="new-password"
                                    placeholder="Neues Passwort wiederholen"
                                    validate={validators.composeValidators(
                                        newPasswordRequired,
                                        passwordMinLength,
                                        passwordMaxLength,
                                        passwordsDoNotMatch
                                    )}
                                />
                            </div>

                            <div className={css.bottomWrapper}>
                                {genericFailure}
                                <PrimaryButton
                                    type="submit"
                                    inProgress={inProgress}
                                    ready={ready}
                                    disabled={submitDisabled}
                                >
                                    <FormattedMessage id="PasswordChangeForm.saveChanges" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    }
}

const { bool, string } = PropTypes;

PasswordChangeFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    changePasswordError: propTypes.error,
    inProgress: bool,
    intl: intlShape.isRequired,
    ready: bool.isRequired,
    formId: string,
    resetPasswordInProgress: bool,
    resetPasswordError: propTypes.error,
};

const PasswordChangeForm = compose(injectIntl)(PasswordChangeFormComponent);
PasswordChangeForm.displayName = 'PasswordChangeForm';

export default PasswordChangeForm;
