import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';

import { updateListingViewsNumber } from '../../util/api';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { getUserWishlist } from '../FavoritePage/FavoritePage.duck';
import { fetchActiveTransactions } from '../ProfilePage/ProfilePage.duck';
import { prepareAndSendSGMessage } from '../TransactionPage/TransactionPage.duck';

import {
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { insertProfileImageToUserData } from '../../util/listings';
import { showListings } from '../../ducks/SdkDataAccessLayer.duck';

const { UUID } = sdkTypes;

const SIMILAR_DISCIPLINE_LISTINGS_NUMBER = 12;

// ================ Action types ================ //
export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const INTIAL_DATA_REQUEST_IN_PROGRESS = 'app/ListingPage/INTIAL_DATA_REQUEST_IN_PROGRESS';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';
export const SHOW_LISTINGS_SUCCESS = 'app/ListingPage/SHOW_LISTINGS_SUCCESS';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';
export const PROVIDE_SEND_ENQUIRY_MIXPANEL = 'app/ListingPage/PROVIDE_SEND_ENQUIRY_MIXPANEL';

export const SEND_COMPLAINT_REPORT_REQUEST = 'app/ListingPage/SEND_COMPLAINT_REPORT_REQUEST';
export const SEND_COMPLAINT_REPORT_SUCCESS = 'app/ListingPage/SEND_COMPLAINT_REPORT_SUCCESS';
export const SEND_COMPLAINT_REPORT_ERROR = 'app/ListingPage/SEND_COMPLAINT_REPORT_ERROR';

export const SET_LISTING_AUTHOR_DATA = 'app/ListingPage/SET_LISTING_AUTHOR_DATA';

export const FETCH_SIMILAR_DISCIPLINE_LISTINGS_REQUEST =
    'app/ListingPage/FETCH_SIMILAR_DISCIPLINE_LISTINGS_REQUEST';
export const FETCH_SIMILAR_DISCIPLINE_LISTINGS_SUCCESS =
    'app/ListingPage/FETCH_SIMILAR_DISCIPLINE_LISTINGS_SUCCESS';
export const FETCH_SIMILAR_DISCIPLINE_LISTINGS_ERROR =
    'app/ListingPage/FETCH_SIMILAR_DISCIPLINE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
    id: null,
    intialDataRequestInProgress: false,
    showListingError: null,
    showListingLoading: false,
    reviews: [],
    extReviews: [],
    fetchReviewsError: null,
    timeSlots: null,
    sendEnquiryInProgress: false,
    sendEnquiryError: null,

    sendComplaintReportInProgress: false,
    sendComplaintReportError: null,
    similarDisciplineListings: [],
    similarDisciplineError: null,
    listingAuthor: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case INTIAL_DATA_REQUEST_IN_PROGRESS:
            return { ...state, intialDataRequestInProgress: payload };

        case SET_INITAL_VALUES:
            return { ...initialState, ...payload };

        case SHOW_LISTING_REQUEST:
            return { ...state, id: payload.id, showListingError: null, showListingLoading: true };
        case SHOW_LISTING_ERROR: {
            return { ...state, showListingError: payload, showListingLoading: false };
        }
        case SHOW_LISTINGS_SUCCESS:
            return { ...state, showListingError: null, showListingLoading: false };

        case FETCH_REVIEWS_REQUEST:
            return { ...state, fetchReviewsError: null };
        case FETCH_REVIEWS_SUCCESS:
            return { ...state, reviews: payload };
        case FETCH_REVIEWS_ERROR:
            return { ...state, fetchReviewsError: payload };

        case SEND_COMPLAINT_REPORT_REQUEST:
            return {
                ...state,
                sendComplaintReportError: null,
                sendComplaintReportInProgress: true,
            };
        case SEND_COMPLAINT_REPORT_SUCCESS:
            return {
                ...state,
                sendComplaintReportError: null,
                sendComplaintReportInProgress: false,
            };
        case SEND_COMPLAINT_REPORT_ERROR:
            return {
                ...state,
                sendComplaintReportError: payload,
                sendComplaintReportInProgress: false,
            };

        case SEND_ENQUIRY_REQUEST:
            return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
        case SEND_ENQUIRY_SUCCESS:
            return { ...state, sendEnquiryInProgress: false };
        case SEND_ENQUIRY_ERROR:
            return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };
        case SET_LISTING_AUTHOR_DATA:
            return { ...state, listingAuthor: payload };

        case FETCH_SIMILAR_DISCIPLINE_LISTINGS_SUCCESS:
            return { ...state, similarDisciplineListings: payload };
        case FETCH_SIMILAR_DISCIPLINE_LISTINGS_ERROR:
            return { ...state, similarDisciplineError: payload };
        case FETCH_SIMILAR_DISCIPLINE_LISTINGS_REQUEST:
            return { ...state, similarDisciplineError: null };
        default:
            return state;
    }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
    type: SET_INITAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

export const initialDataRequest = inProgress => ({
    type: INTIAL_DATA_REQUEST_IN_PROGRESS,
    payload: inProgress,
});

export const showListingRequest = id => ({
    type: SHOW_LISTING_REQUEST,
    payload: { id },
});

export const showListingError = e => ({
    type: SHOW_LISTING_ERROR,
    error: true,
    payload: e,
});

export const showListingSuccess = () => ({
    type: SHOW_LISTINGS_SUCCESS,
});

export const fetchReviewsRequest = () => ({
    type: FETCH_REVIEWS_REQUEST,
});
export const fetchReviewsSuccess = reviews => ({
    type: FETCH_REVIEWS_SUCCESS,
    payload: reviews,
});
export const fetchReviewsError = error => ({
    type: FETCH_REVIEWS_ERROR,
    error: true,
    payload: error,
});

export const fetchSimilarDisciplineListingsRequest = () => ({
    type: FETCH_SIMILAR_DISCIPLINE_LISTINGS_REQUEST,
});
export const fetchSimilarDisciplineListingsSuccess = listings => ({
    type: FETCH_SIMILAR_DISCIPLINE_LISTINGS_SUCCESS,
    payload: listings,
});
export const fetchSimilarDisciplineListingsError = error => ({
    type: FETCH_SIMILAR_DISCIPLINE_LISTINGS_ERROR,
    payload: error,
});

export const setListingAuthor = author => ({
    type: SET_LISTING_AUTHOR_DATA,
    payload: author,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = txId => ({ type: SEND_ENQUIRY_SUCCESS, payload: txId });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });
export const provideSendEnquiryMixpanel = payload => ({
    type: PROVIDE_SEND_ENQUIRY_MIXPANEL,
    payload,
});

export const sendComplaintReportRequest = () => ({ type: SEND_COMPLAINT_REPORT_REQUEST });
export const sendComplaintReportSuccess = () => ({ type: SEND_COMPLAINT_REPORT_SUCCESS });
export const sendComplaintReportError = e => ({
    type: SEND_COMPLAINT_REPORT_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => async (dispatch, getState, sdk) => {
    const {
        ListingPage: { showListingLoading },
    } = getState();
    if (showListingLoading) return;

    dispatch(showListingRequest(listingId));
    dispatch(fetchCurrentUser());
    const params = {
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',

            // Social media
            'variants.facebook',
            'variants.twitter',

            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',

            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : dispatch(showListings(params));

    return show
        .then(response => {
            dispatch(showListingSuccess());
            dispatch(addMarketplaceEntities(response));
            dispatch(fetchSimilarDisciplineListings(response.data.data));
            return response;
        })
        .catch(e => {
            dispatch(showListingError(storableError(e)));
        });
};

export const fetchReviews = userId => (dispatch, getState, sdk) => {
    if (!userId) {
        return dispatch(fetchReviewsError(storableError('User id is missed.')));
    }
    dispatch(fetchReviewsRequest());
    sdk.reviews
        .query({
            subject_id: userId,
            state: 'public',
            include: ['author', 'author.profileImage'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
        .then(response => {
            const reviews = denormalisedResponseEntities(response);
            dispatch(fetchReviewsSuccess(reviews));
        })
        .catch(e => {
            dispatch(fetchReviewsError(storableError(e)));
        });
};

export const sendEnquiry = (listingId, message, listingSubstitutionId) => async (
    dispatch,
    getState,
    sdk
) => {
    dispatch(sendEnquiryRequest());

    const customerData = {
        userUUID: getState().user.currentUser.id.uuid,
        name: getState().user.currentUser.attributes.profile.displayName,
    };

    const { currentUser } = getState().user;
    const {
        publicData: { userType },
    } = currentUser.attributes.profile;

    const bodyParams = {
        transition: TRANSITION_ENQUIRE,
        processAlias: config.bookingProcessAlias,
        params: {
            listingId,
            protectedData: {
                initiatedAs: userType,
                customerData: JSON.stringify(customerData),
                listingSubstitutionId,
            },
        },
    };

    return sdk.transactions
        .initiate(bodyParams, { expand: true, include: ['customer', 'provider', 'listing'] })
        .then(async response => {
            const transactionId = response.data.data.id;

            dispatch(addMarketplaceEntities(response));

            await sdk.messages.send({ transactionId, content: message });

            dispatch(sendEnquirySuccess(transactionId));
            dispatch(
                provideSendEnquiryMixpanel({
                    listingId,
                    listingSubstitutionId,
                    transactionId,
                })
            );
            dispatch(fetchCurrentUserHasOrdersSuccess(true));

            dispatch(
                prepareAndSendSGMessage({
                    action: 'transactionInitiated',
                    txId: transactionId.uuid,
                    dataParams: { message },
                })
            );

            return transactionId;
        })
        .catch(error => {
            dispatch(sendEnquiryError(storableError(error)));

            throw error;
        });
};

export const submitComplaintReport = body => async (dispatch, getState, sdk) => {
    dispatch(sendComplaintReportRequest());

    return fetch(`/api/reporting/suspicious-listing`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(res => {
            if (res.status !== 200) {
                throw new Error('Unable to send data');
            }
            dispatch(sendComplaintReportSuccess());
            return {};
        })
        .catch(error => {
            dispatch(sendComplaintReportError(error.message));
            return { error: error.message };
        });
};

export const fetchSimilarDisciplineListings = listingData => (dispatch, getState, sdk) => {
    dispatch(fetchSimilarDisciplineListingsRequest());

    const noDisciplines =
        !listingData.attributes.publicData.desiredDisciplines ||
        listingData.attributes.publicData.desiredDisciplines.length === 0;

    if (noDisciplines) {
        return dispatch(fetchSimilarDisciplineListingsSuccess([]));
    }

    const queryParams = {
        page: 1,
        perPage: SIMILAR_DISCIPLINE_LISTINGS_NUMBER,
        pub_desiredDisciplines: `has_any:${listingData.attributes.publicData.desiredDisciplines.join(
            ','
        )}`,
        pub_type: 'horse',
        /** warning
         * the similar fields exist on the SearchPage
         * every listing\user (etc) data from request
         * is merged, and if new request is lack of some field,
         * it will be removed after through denormalisedEntities function
         */
        include: ['author', 'images'],
        'fields.listing': [
            'title',
            'geolocation',
            'price',
            'publicData',
            'metadata',
            'description',
            'createdAt',
        ],
        'fields.user': [
            'profile.displayName',
            'profile.abbreviatedName',
            'profile.bio',
            'profile.publicData',
        ],
        'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.square-small',
            'variants.scaled-medium',
        ],
    };

    return sdk.listings
        .query(queryParams)
        .then(response => {
            const { data: listings } = response.data;

            dispatch(addMarketplaceEntities(response));
            dispatch(fetchSimilarDisciplineListingsSuccess(listings));
        })
        .catch(error => {
            dispatch(fetchSimilarDisciplineListingsError(error));
            throw error;
        });
};

export const loadData = params => async (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
        ListingPage: { intialDataRequestInProgress },
    } = getState();

    if (intialDataRequestInProgress) return;

    dispatch(initialDataRequest(true));

    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;
    const listingId = new UUID(params.id);
    const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
    const isOwnListing = ownListingVariants.includes(params.variant);
    const typeToCheck = isOwnListing ? 'currentUser' : 'user';

    const checkUser = item => item.type == typeToCheck;

    const currentListing = await dispatch(showListing(listingId, isOwnListing));
    const authorProfile = currentListing ? currentListing.data.included.filter(checkUser) : null;
    const authorId = authorProfile && authorProfile[0] ? authorProfile[0].id.uuid : null;
    const isOwnerView = currentUserId && currentUserId === authorId;

    if (authorProfile && authorProfile.length) {
        dispatch(setListingAuthor(insertProfileImageToUserData(authorProfile[0], getState())));
    }

    try {
        if (!isOwnerView) {
            updateListingViewsNumber(params.id);
        }
    } catch (e) {
        // do nothing
    }

    if (config.enableAvailability) {
        return Promise.all([
            // dispatch(showListing(listingId)), add isOwnListing
            // dispatch(fetchTimeSlots(listingId)),
            dispatch(fetchReviews(authorId)),
            dispatch(getUserWishlist()),
            dispatch(fetchActiveTransactions()),
            dispatch(initialDataRequest(false)),
        ]);
    } else {
        return Promise.all([
            // dispatch(showListing(listingId)), add isOwnListing
            dispatch(fetchReviews(authorId)),
            dispatch(getUserWishlist()),
            dispatch(fetchActiveTransactions()),
            dispatch(initialDataRequest(false)),
        ]);
    }
};
