import React from 'react';

import EmailIcon from './EmailIcon';
import WhatsAppShareButton from './WhatsAppShareButton.js';
import CopyLinkButton from './CopyLinkButton.js';
import FacebookShareButton from './FacebookShareButton.js';

import css from './ShareViaSocialNetworkButtons.css';

export default ({
    shareLink,
    subjectMail,
    subjectBody,
    copyAllowed = true,
    waTitle = 'Auf WhatsApp teilen.',
    waText = 'Sieh dir dieses Pferd bei HorseDeal an!',
}) => {
    const emailLink = `mailto:?body=${subjectBody}&subject=${subjectMail}`;

    return (
        <div className={css.shareContainer}>
            {copyAllowed && <CopyLinkButton url={shareLink} text="Link kopieren" />}
            <p className={css.shareItem} onClick={() => window.open(emailLink)}>
                <EmailIcon />
                <span>Per E-Mail senden</span>
            </p>
            <FacebookShareButton
                url={shareLink}
                quote="Sieh dir diese Pferd bei HorseDeal an!"
                text="Teilen auf Facebook"
            />
            <WhatsAppShareButton url={shareLink} title={waTitle} text={waText} />
        </div>
    );
};
