import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Impressum.css';

const Impressum = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Letztes Update: 14. Februar 2024</p>

      <p>
        <h2>Adresse</h2><br/>
        HorseDeal AG<br/>
        Bösch 80A<br/>
        6331 Hünenberg<br/>
        <br/>
        HorseDeal ist eine Marke der HorseDeal AG.<br/>
        <br/>
        Geschäftsführer: Benjamin Kröni<br/>
        Telefon: 0800 444 550<br/>
        E-Mail: hello@horsedeal.com<br/>
        <br/>
        UID: CHE-211.894.969<br/>
        Sitz: Kanton Zug<br/>
        <br/>
        <h2>Rechtlicher Hinweis</h2><br/>
        Im Hinblick auf die technischen Eigenschaften des Internet kann kein Gewähr für die Authentizität, 
        Richtigkeit und Vollständigkeit der im Internet zur Verfügung gestellten Informationen übernommen werden. 
        Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen Webseite und ihrer 
        Inhalte übernommen.<br/>
        <br/>
        Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus 
        der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Website erwachsen, wird, soweit 
        rechtlich zulässig, ausgeschlossen.<br/>
        <br/>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. 
        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten 
        ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt 
        der Verlinkung auf mögliche Rechtsverstösse überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht 
        erkennbar.<br/>
        <br/>
        <h2>Urheberrecht</h2><br/>
        Wir sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie 
        lizenzfreie Werke zurückzugreifen. Die durch uns erstellten Inhalte und Werke auf dieser Webseite unterliegen 
        dem Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
        der Verwertung ausserhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. 
        Erstellers. Downloads und Kopien dieser Website sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br/>
        <br/>
        <h2>Kontakt</h2><br/>
        Hast Du Fragen? <a href="/kontakt">Hier klicken</a>, um unsere Kontaktseite zu besuchen.
        <br/>
      </p>
    </div>
  );
};

const { string } = PropTypes;

Impressum.propTypes = {
    rootClassName: string,
    className: string,
};

export default Impressum;
