import React from 'react';
import css from './ShareViaSocialNetworkButtons.css';
import { FacebookShareButton as FacebookShareButtonRS } from 'react-share';

const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_18039_32654)">
            <path
                d="M24 12C24 5.4 18.6 0 12 0C5.4 0 0 5.4 0 12C0 18 4.35 22.95 10.05 23.85V15.45H7.05V12H10.05V9.3C10.05 6.3 11.85 4.65 14.55 4.65C15.9 4.65 17.25 4.95 17.25 4.95V7.95H15.75C14.25 7.95 13.8 8.85 13.8 9.75V12H17.1L16.5 15.45H13.65V24C19.65 23.1 24 18 24 12Z"
                fill="#1A2B49"
            />
        </g>
        <defs>
            <clipPath id="clip0_18039_32654">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ({ url = '', quote = '', text = '' }) => (
    <FacebookShareButtonRS url={url} quote={quote} className={css.shareItem}>
        {icon}
        <span>{text}</span>
    </FacebookShareButtonRS>
);
