import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import {
    ModalPortal,
    Button,
    FieldTextInput,
    Alert,
    FieldRadioButton,
    IconSpinner,
} from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { composeValidators, required } from '../../util/validators';

import css from './InquiryModal.css';
import { useAssets } from '../../hooks/useAssets';
import classNames from 'classnames';
import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import { resolveStatusByMatchingMatrixScore } from '../../util/user';
import { addIncludedDataToListing } from './helpers';
import { useListings } from '../../hooks/useListings';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ensureUser } from '../../util/data';
import { connect } from 'react-redux';

const { userTypeHorseowner } = config;

export const CHOOSE_HORSE_STEP = 'chooseHorse';
export const SEND_INQUIRY_STEP = 'sendInquiry';

const ListingRadioButton = ({
    assetsLoadingRequests,
    listing,
    riderListing,
    form,
    maxScore,
    setMaxScore,
    mainHorseId,
    horseListingSelected,
}) => {
    const {
        id: { uuid: listingId },
        attributes: { title },
    } = listing;

    const listingAssetsRequestInProgress =
        assetsLoadingRequests && assetsLoadingRequests[listingId];

    const images = useAssets({
        listing,
        allowedTypes: ['image'],
    });

    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix({
        riderListingId: riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: listingId,
    });

    const firstImage =
        images && images[0] ? (
            <img
                className={css.listingAvatar}
                src={
                    images.reduce(
                        (acc, image) => {
                            const { position } = image;
                            if (acc.position > position) {
                                return image;
                            }
                            return acc;
                        },
                        { position: Infinity }
                    ).attributes.variants['landscape-crop'].url
                }
            />
        ) : null;

    const listingIdentityData = firstImage || (
        <div className={css.identityPlaceholder}>
            {title
                .split(' ')
                .map(nameUnit => nameUnit.slice(0, 1))
                .splice(0, 2)
                .join(' ')}
        </div>
    );

    const loading = matchingMatrixLoading || listingAssetsRequestInProgress;

    const score = (matchingScore && matchingScore.finalScore) || 0;
    const scorePalette = resolveStatusByMatchingMatrixScore(score);

    useEffect(() => {
        /**
         * if a horseowner doesn't have mainHorseId data,
         * pre-select a horse with a hightest score,
         * otherwise set horseListing form field as mainHorseId
         */
        const isMainHorse = mainHorseId === listingId;
        if (mainHorseId && !isMainHorse) {
            return;
        }

        if (isMainHorse || !maxScore || (maxScore && score > maxScore)) {
            setMaxScore(score);

            form.change('matchingScore', scorePalette);
            form.change('listingId', listingId);
            form.change('listingTitle', title);
        }
    }, [score, mainHorseId]);

    return (
        <div className={css.listingData}>
            {loading ? (
                <IconSpinner />
            ) : (
                <FieldRadioButton
                    className={css.radioButton}
                    id={listingId}
                    value={listingId}
                    form={form}
                    notifyOnChange={() => {
                        if (horseListingSelected === listingId) {
                            form.change('matchingScore', scorePalette);
                            form.change('listingTitle', title);
                        }
                    }}
                    name="listingId"
                    label={
                        <div className={css.listingIdentity}>
                            <div>
                                {listingIdentityData}
                                <p>{title}</p>
                            </div>
                            <mark className={classNames([css.scoreBadge, css[scorePalette]])}>
                                {score}%
                            </mark>
                        </div>
                    }
                />
            )}
        </div>
    );
};

const SendInquiryForm = ({ intl, matchingScore }) => {
    const mapper = {
        insufficient: 'failure',
        sufficient: 'warning',
        total: 'success',
    };

    return (
        <>
            <Alert
                header={`InquiryModal.alertHeading-${matchingScore}`}
                description={`InquiryModal.alertDesc-${matchingScore}`}
                closeAllowed={false}
                type={mapper[matchingScore]}
                rootClassName={css.alert}
            />
            <aside>
                <FieldTextInput
                    id="comment"
                    name="comment"
                    type="textarea"
                    maxLength={1000}
                    placeholder={intl.formatMessage({
                        id: 'InquiryModal.messageLabel',
                    })}
                    validate={composeValidators(
                        required(
                            intl.formatMessage({
                                id: 'CollectUserInfoWizard.fieldRequiredMessage',
                            })
                        )
                    )}
                />
            </aside>
        </>
    );
};

const ChooseHorseForm = ({ listings, publicUserName, ...rest }) => {
    const { riderListing, form, setStep } = rest;
    const noListings = !listings || (listings && listings.length === 0);

    const isSingleListing = listings && listings.length === 1;
    const [matchingMatrixLoading, matchingScore, fulfilled] = useMatchingMatrix({
        riderListingId:
            isSingleListing && riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: isSingleListing ? listings[0].id.uuid : null,
    });

    useEffect(() => {
        /**
         * if a horseowner has a single listing, pre-select it in the form
         */
        if (matchingMatrixLoading || !fulfilled) {
            return;
        }
        if (isSingleListing) {
            const listing = listings[0];
            const {
                id: { uuid: listingId },
                attributes: { title },
            } = listing;

            const score = (matchingScore && matchingScore.finalScore) || 0;
            const scorePalette = resolveStatusByMatchingMatrixScore(score);

            form.change('matchingScore', scorePalette);
            form.change('listingId', listingId);
            form.change('listingTitle', title);

            setStep(SEND_INQUIRY_STEP);
        }
    }, [isSingleListing, matchingMatrixLoading, matchingScore, fulfilled]);

    if (noListings) {
        return (
            <p className={css.error}>
                <FormattedMessage id="InquiryModal.noListings" values={{ publicUserName }} />
            </p>
        );
    }

    return matchingMatrixLoading ? (
        <div className={css.loadingHolder}>
            <IconSpinner />
        </div>
    ) : (
        <main className={css.listingsDataHolder}>
            {listings &&
                listings.length &&
                listings.map(listing => (
                    <ListingRadioButton key={listing.id.uuid} listing={listing} {...rest} />
                ))}
        </main>
    );
};

const InquiryModal = ({
    isOpen,
    onClose,
    publicUser,
    currentUser,
    intl,
    ridingListingAuthorId,
    assetsLoadingRequests,
    dispatch, // ???
    onSendEnquiry,
    sendEnquiryError,
    sendEnquiryInProgress,
    activeTransactionsDataInProgress,
    activeTransactionsDataError,
    activeTransactionsData,
    history,
    initialListing,
    // match,
    ...restProps
}) => {
    const [error, setError] = useState(null);
    const [step, setStep] = useState(CHOOSE_HORSE_STEP);
    const [maxScore, setMaxScore] = useState(null);

    const [riderListings, riderListingLoading, , fulfilled] = useListings({
        params: {
            authorId: ridingListingAuthorId,
        },
        allowed: !!ridingListingAuthorId,
    });
    const riderListing = riderListings ? riderListings[0] : null;

    const {
        id: { uuid: currentUserId },
        attributes: {
            profile: {
                publicData: { mainHorseId, userType },
            },
        },
    } = currentUser;
    const {
        id: publicUserIdContainer,
        attributes: {
            profile: { firstName: publicUserName },
        },
    } = ensureUser(publicUser);
    const { uuid: publicUserId } = publicUserIdContainer || { uuid: null };
    const isOwner = userType === userTypeHorseowner;
    const riderListingId = riderListing && riderListing.id ? riderListing.id.uuid : null;

    const inquiryDisabledForOwner = isOwner && (!riderListing || !riderListingId);

    /**
     * rider's listing is closed, user is not available
     */
    const inquiryDisabledForRider = !isOwner && !riderListing;

    const authorId = isOwner ? currentUserId : publicUserId;

    const [horseownerListings, horseownerListingsInProgress, included] = useListings({
        params: {
            authorId,
        },
        allowed: !!authorId,
    });

    const horseownerListingsIdsStr = (horseownerListings || [])
        .map(({ id: { uuid } }) => uuid)
        .join(',');

    const filterActiveTxListings = ({ id: { uuid } }) =>
        !(
            activeTransactionsData &&
            activeTransactionsData[uuid] &&
            activeTransactionsData[uuid][currentUserId] &&
            activeTransactionsData[uuid][publicUserId]
        );

    const listings = (
        (initialListing
            ? [initialListing]
            : horseownerListings && included
            ? addIncludedDataToListing(horseownerListings, included)
            : null) || []
    ).filter(filterActiveTxListings);

    const loading =
        horseownerListingsInProgress ||
        activeTransactionsDataInProgress ||
        riderListingLoading ||
        !fulfilled;

    const stepConfig = {
        [SEND_INQUIRY_STEP]: {
            renderFormContent: (props = {}) => <SendInquiryForm intl={intl} {...props} />,
            props: {
                contentClassName: css.modalContent,
            },
            action: async ({ listingId: listingIdValue, comment }) => {
                const ownerEnquiry = isOwner && riderListingId;
                const listingId = ownerEnquiry ? riderListingId : listingIdValue;
                const listingSubstitution = ownerEnquiry ? listingIdValue : null;
                const txId = await onSendEnquiry(listingId, comment, listingSubstitution);

                if (txId && txId.uuid) {
                    history.push(
                        createResourceLocatorString(
                            'OrderDetailsPage',
                            routeConfiguration(),
                            { id: txId.uuid },
                            {}
                        )
                    );
                }
            },
        },
        [CHOOSE_HORSE_STEP]: {
            renderFormContent: (props = {}) => (
                <ChooseHorseForm
                    {...props}
                    listings={listings}
                    assetsLoadingRequests={assetsLoadingRequests}
                    riderListing={riderListing}
                    riderListingLoading={riderListingLoading}
                    maxScore={maxScore}
                    setMaxScore={setMaxScore}
                    setStep={setStep}
                    mainHorseId={mainHorseId}
                    publicUserName={publicUserName}
                />
            ),
            props: {
                contentClassName: classNames([css.modalContentHorseListingStep, css.modalContent]),
            },
            action: () => setStep(SEND_INQUIRY_STEP),
        },
    };

    const isSendInquiry = step === SEND_INQUIRY_STEP;

    const { renderFormContent, props, action } = stepConfig[step];

    useEffect(() => {
        setError(
            inquiryDisabledForOwner || inquiryDisabledForRider ? 'inquiryDisabledForOwner' : null
        );
    }, [inquiryDisabledForOwner, inquiryDisabledForRider]);

    useEffect(() => {
        if (sendEnquiryError || activeTransactionsDataError) {
            const { apiErrors } = sendEnquiryError || activeTransactionsDataError;
            const { code } = apiErrors[0];
            setError(code);
        }
    }, [sendEnquiryError, activeTransactionsDataError]);

    useEffect(() => {
        if (!horseownerListingsIdsStr) return;

        /**
         * if a HO has a single listing and a rider already has tx with it;
         * if a rider has txs with all available HO listings
         */

        const listingsWithActiveTx = horseownerListings.filter(l => !filterActiveTxListings(l));
        const allListingsHasAlreadyTx = horseownerListings.length === listingsWithActiveTx.length;

        if (allListingsHasAlreadyTx) {
            setError('noAvailableListingsForNewTx');
        }
    }, [horseownerListingsIdsStr]);

    return (
        <ModalPortal
            id="inquiry-modal"
            isOpen={isOpen}
            onClose={loading ? () => null : onClose}
            containerClassName={css.modalContainer}
            {...props}
            containerClassNameJoined
            isSticky
        >
            {loading ? (
                <div className={css.loadingHolder}>
                    <IconSpinner />
                </div>
            ) : (
                <FinalForm
                    {...restProps}
                    onSubmit={() => null}
                    render={fieldRenderProps => {
                        const { values, form } = fieldRenderProps;
                        const { listingId, listingTitle, matchingScore, comment } = values || {};

                        const disabled = isSendInquiry ? !comment : !listingId || !matchingScore;
                        const userName =
                            publicUser && publicUser.attributes.profile
                                ? publicUser.attributes.profile.displayName.split(' ')[0]
                                : '';

                        return (
                            <>
                                <h4 className={css.modalHeading}>
                                    <FormattedMessage
                                        id={`InquiryModal.heading-${step}`}
                                        values={{ userName }}
                                    />
                                </h4>
                                <p>
                                    <FormattedMessage
                                        id={`InquiryModal.desc-${step}-${userType}`}
                                        values={{ userName, listingTitle }}
                                    />
                                </p>

                                {!error &&
                                    renderFormContent({
                                        form,
                                        matchingScore,
                                        horseListingSelected: listingId,
                                    })}

                                <footer>
                                    {error && (
                                        <p className={css.error}>
                                            <FormattedMessage
                                                id={`InquiryModal.error-${
                                                    typeof error === 'string' ? error : 'default'
                                                }`}
                                            />
                                        </p>
                                    )}
                                    {isSendInquiry && (
                                        <p className={css.sidenote}>
                                            <FormattedMessage
                                                id="InquiryModal.sidenote"
                                                values={{ userName }}
                                            />
                                        </p>
                                    )}
                                    <Button
                                        onClick={() => action(values)}
                                        disabled={disabled || error || sendEnquiryInProgress}
                                        type="button"
                                        inProgress={sendEnquiryInProgress}
                                    >
                                        <FormattedMessage id={`InquiryModal.action-${step}`} />
                                    </Button>
                                </footer>
                            </>
                        );
                    }}
                />
            )}
        </ModalPortal>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default compose(withRouter, connect(null, mapDispatchToProps), injectIntl)(InquiryModal);
