import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M1.815 4.278a.792.792 0 0 0-.462.353l-.093.149v7.36c0 6.875.005 7.368.069 7.485a.817.817 0 0 0 .468.374c.105.028 3.404.038 10.288.031 10.133-.01 10.135-.01 10.274-.092a1.03 1.03 0 0 0 .26-.253l.121-.172.011-7.226c.007-5.161-.002-7.288-.034-7.441a.727.727 0 0 0-.528-.56c-.211-.053-20.153-.061-20.374-.008m18.283 1.517c-.141.128-6.678 5.131-6.838 5.232a2.386 2.386 0 0 1-2.325.106c-.199-.102-1.398-1-3.623-2.713L3.923 5.811c-.049-.038 1.67-.049 8.075-.05 4.596 0 8.121.014 8.1.034M10.08 12.378c1.176.683 2.774.665 3.912-.044.166-.104 1.865-1.391 3.775-2.86l3.473-2.671V18.56H2.76V6.803l3.51 2.7c1.931 1.485 3.645 2.779 3.81 2.875"
            fillRule="evenodd"
            fill="#000"
        />
    </svg>
);
