import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, Button, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';

import css from './PasswordResetForm.css';

const { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } = validators;

export default props => (
    <FinalForm
        {...props}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                formId,
                handleSubmit,
                inProgress,
                invalid,
                form,
            } = fieldRenderProps;

            const passwordRequired = validators.requiredStringNoTrim('Dieses Feld wird benötigt');
            const passwordMinLength = validators.minLength(
                `Dein Passwort sollte mindestens ${PASSWORD_MIN_LENGTH} Zeichen umfassen`,
                PASSWORD_MIN_LENGTH
            );
            const passwordMaxLength = validators.maxLength(
                `Dein Passwort sollte maximal ${PASSWORD_MAX_LENGTH} Zeichen umfassen`,
                PASSWORD_MAX_LENGTH
            );
            const passwordsDoNotMatch = validators.passwordsDoNotMatch(
                'Die Passwörter stimmen nicht überein.',
                {
                    finalForm: form,
                    passwordFieldName: 'password',
                    confirmationFieldName: 'passwordConfirmation',
                }
            );

            const classes = classNames(rootClassName || css.root, className);

            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress;

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <FieldTextInput
                        type="password"
                        id={formId ? `${formId}.password` : 'password'}
                        name="password"
                        autoComplete="new-password"
                        placeholder="Neues Passwort"
                        validate={validators.composeValidators(
                            passwordRequired,
                            passwordMinLength,
                            passwordMaxLength
                        )}
                    />
                    <FieldTextInput
                        type="password"
                        id={formId ? `${formId}.passwordConfirmation` : 'passwordConfirmation'}
                        name="passwordConfirmation"
                        placeholder="Neues Passwort wiederholen"
                        validate={validators.composeValidators(
                            passwordRequired,
                            passwordsDoNotMatch
                        )}
                    />
                    <Button type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                        Passwort speichern
                    </Button>
                </Form>
            );
        }}
    />
);
