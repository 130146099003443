import React from 'react';
import css from './InboxItem.css';
import {
    txIsEnquired,
    txIsDeclined,
    txIsAccepted,
    txIsExpired,
    FILE_MESSAGE_TEXT_CONTENT,
} from '../../../util/transaction';

import { FormattedMessage } from '../../../util/reactIntl';

export const getDateDescription = date => {
    const now = new Date();

    const minutes = ((now - date) / (1000 * 60)).toFixed();
    const hours = Math.floor(minutes / 60);
    const days = (hours / 24).toFixed();
    const months = (days / 30).toFixed();

    if (minutes < 59) {
        return <FormattedMessage id="InboxPage.someMinutesAgo" />;
    }
    if (minutes > 59 && minutes < 120) {
        return <FormattedMessage id="InboxPage.hourAgo" values={{ hours }} />;
    }
    if (minutes > 119 && hours < 24) {
        return <FormattedMessage id="InboxPage.hoursAgo" values={{ hours }} />;
    }
    if (hours > 23 && days < 2) {
        return <FormattedMessage id="InboxPage.dayAgo" values={{ days }} />;
    }
    if (days > 1 && days < 29) {
        return <FormattedMessage id="InboxPage.daysAgo" values={{ days }} />;
    }
    if (months == 1) {
        return <FormattedMessage id="InboxPage.monthAgo" values={{ months }} />;
    }
    if (months > 1) {
        return <FormattedMessage id="InboxPage.monthsAgo" values={{ months }} />;
    }
};

export const formatDate = date =>
    date && date instanceof Date
        ? {
              long: getDateDescription(date),
              short: date.toLocaleDateString('de-DE', {
                  weekday: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  month: 'numeric',
              }),
          }
        : null;

export const resolveLastMessageContent = (messageContent, intl) => {
    if (messageContent === FILE_MESSAGE_TEXT_CONTENT) {
        return intl.formatMessage({
            id: 'InboxPage.fileSent',
        });
    } else {
        return messageContent;
    }
};

export const txState = tx => {
    switch (true) {
        case txIsEnquired(tx):
            return {
                stateClassName: css.stateActionNeeded,
                state: 'InboxPage.chatRequested',
            };
        case txIsAccepted(tx):
            return {
                stateClassName: css.stateSucces,
                state: 'InboxPage.chatAccepted',
            };
        case txIsDeclined(tx):
            return {
                stateClassName: css.stateFailed,
                state: 'InboxPage.chatDeclined',
            };
        case txIsExpired(tx):
            return {
                stateClassName: css.stateExpired,
                state: 'InboxPage.chatExpired',
            };

        default:
            console.warn('This transition is unknown:', tx.attributes.lastTransition);
            break;
    }
};

export const resolveLastChatActivityContent = (transactionMessage, transactionTransition) => {
    const {
        attributes: { content: lastMessageContent = '...', createdAt: lastMessageDate },
    } = transactionMessage;
    const { createdAt: transitionDate } = transactionTransition;
    /** show content of the latest message or transition */
    return transitionDate > lastMessageDate
        ? { date: transitionDate, content: transactionTransition.content }
        : { date: lastMessageDate, content: lastMessageContent };
};

export const getFormattedDate = date =>
    date
        .replace(/,/, '')
        .replace(/\.\d\./g, m => m.slice(0, 1) + 0 + m.slice(1))
        .replace(/ \d\./g, m => ' 0' + m.slice(1));
