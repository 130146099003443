import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import config from '../../config';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureCurrentUser } from '../../util/data';
import { updateUserProfileInfo } from '../../ducks/user.duck';
import { fetchAppointments } from '../TransactionPage/TransactionPage.duck';
import { queryTransactions } from '../../ducks/SdkDataAccessLayer.duck';

const { userTypeHorseowner, userTypeRider } = config;

const sortedTransactions = txs =>
    reverse(
        sortBy(txs, tx => {
            return tx.attributes ? tx.attributes.lastTransitionedAt : null;
        })
    );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const ADD_ALL_USER_MESSAGES_REQUEST = 'app/InboxPage/ADD_ALL_USER_MESSAGES_REQUEST';
export const ADD_ALL_USER_MESSAGES_SUCCESS = 'app/InboxPage/ADD_ALL_USER_MESSAGES_SUCCESS';
export const ADD_ALL_USER_MESSAGES_FAILURE = 'app/InboxPage/ADD_ALL_USER_MESSAGES_FAILURE';

export const ARCHIEVE_TRANSACTION_REQUEST = 'app/InboxPage/ARCHIEVE_TRANSACTION_REQUEST';
export const ARCHIEVE_TRANSACTION_SUCCESS = 'app/InboxPage/ARCHIEVE_TRANSACTION_SUCCESS';
export const ARCHIEVE_TRANSACTION_ERROR = 'app/InboxPage/ARCHIEVE_TRANSACTION_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
    entities.map(entity => ({
        id: entity.id,
        type: entity.type,
    }));

const initialState = {
    fetchInProgress: false,
    fetchOrdersOrSalesError: null,
    pagination: null,
    transactionRefs: [],
    allUserMessages: [],
    allUserMessagesRequestInProgress: false,
    allUserMessagesError: null,
    archievedTransactionsError: false,
    archievedTransactionsLoading: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ORDERS_OR_SALES_REQUEST:
            return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
        case FETCH_ORDERS_OR_SALES_SUCCESS: {
            const transactions = sortedTransactions(payload.data.data);

            return {
                ...state,
                fetchInProgress: false,
                transactionRefs: entityRefs(transactions),
                pagination: payload.data.meta,
            };
        }
        case FETCH_ORDERS_OR_SALES_ERROR:
            return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
        case ADD_ALL_USER_MESSAGES_REQUEST:
            return {
                ...state,
                allUserMessagesRequestInProgress: true,
                allUserMessagesError: null,
            };
        case ADD_ALL_USER_MESSAGES_SUCCESS:
            return {
                ...state,
                allUserMessages: payload,
                allUserMessagesRequestInProgress: false,
                allUserMessagesError: null,
            };
        case ADD_ALL_USER_MESSAGES_FAILURE:
            return {
                ...state,
                allUserMessages: [],
                allUserMessagesRequestInProgress: false,
                allUserMessagesError: payload,
            };

        case ARCHIEVE_TRANSACTION_REQUEST:
            return {
                ...state,
                archievedTransactionsLoading: true,
                archievedTransactionsError: null,
            };
        case ARCHIEVE_TRANSACTION_SUCCESS:
            return {
                ...state,
                archievedTransactionsLoading: false,
                archievedTransactionsError: null,
            };
        case ARCHIEVE_TRANSACTION_ERROR:
            return {
                ...state,
                archievedTransactionsLoading: false,
                fetchOrdersOrSalesError: payload,
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
    type: FETCH_ORDERS_OR_SALES_SUCCESS,
    payload: response,
});
const fetchOrdersOrSalesError = e => ({
    type: FETCH_ORDERS_OR_SALES_ERROR,
    error: true,
    payload: e,
});

const addAllUserMessagesRequest = () => ({
    type: ADD_ALL_USER_MESSAGES_REQUEST,
});
const addAllUserMessagesSuccess = messages => ({
    type: ADD_ALL_USER_MESSAGES_SUCCESS,
    payload: messages,
});
const addAllUserMessagesFailure = error => ({
    type: ADD_ALL_USER_MESSAGES_FAILURE,
    payload: error,
});

const requestArchievedTransactionData = () => ({
    type: ARCHIEVE_TRANSACTION_REQUEST,
});

const requestArchievedTransactionSuccess = () => ({
    type: ARCHIEVE_TRANSACTION_SUCCESS,
});

const requestArchievedTransactionFailure = payload => ({
    type: ARCHIEVE_TRANSACTION_ERROR,
    payload,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;
/**
 * apiQueryParams for initial data load
 */
const apiQueryParams = {
    // only: onlyFilter,
    lastTransitions: TRANSITIONS,
    include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
    ],
    'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'protectedData',
    ],
    'fields.user': [
        'banned',
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.bio',
        'profile.publicData.country',
        'profile.publicData.availabilityStatus',
        'profile.publicData.chatLastActivity',
        'profile.publicData.userType',
    ],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
};

export const fetchTransactionsData = (params = {}) => async (dispatch, getState, sdk) =>
    dispatch(queryTransactions(params))
        .then(response => response)
        .catch(e => {
            throw e;
        });

const addMessages = response => (dispatch, getState, sdk) => {
    dispatch(addAllUserMessagesRequest());

    Promise.all(
        response.data.data.map(async ({ id }) => {
            const messages = await sdk.messages.query({
                transactionId: id,
                include: ['sender'],
            });

            return { id, data: messages.data.data };
        })
    )
        .then(response => {
            const messages = response.reduce(
                (acc, { id: { uuid }, data }) => ({
                    ...acc,
                    [uuid]: data,
                }),
                {}
            );

            dispatch(addAllUserMessagesSuccess(messages));
        })
        .catch(err => {
            dispatch(addAllUserMessagesFailure(err));
        });
};

const filterTxByRole = (response, user) => {
    const {
        id,
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = user;

    const currentUserId = id.uuid;

    if (!currentUserId) {
        return response;
    }

    response.data.data = response.data.data.reduce((acc, tx) => {
        const {
            attributes: { protectedData },
            relationships: {
                provider: {
                    data: {
                        id: { uuid: providerId },
                    },
                },
                customer: {
                    data: {
                        id: { uuid: customerId },
                    },
                },
            },
        } = tx;

        const { initiatedAs } = protectedData || {};

        if (!initiatedAs) {
            return acc;
        }
        const initiatedAsOwner = initiatedAs === userTypeHorseowner;
        const initatedAsRider = initiatedAs === userTypeRider;

        const isHorseowner = userType === userTypeHorseowner;

        const isProvider = providerId === currentUserId;
        const isCustomer = customerId === currentUserId;

        /**
         * tx is initiated by other party
         * current user becomes tx provider
         * initiated by other owner
         * current role is also owner
         * invalidRole
         */
        if (isProvider && initiatedAsOwner && isHorseowner) {
            return acc;
        }
        /**
         * tx is initiated by current user
         * current user becomes tx customer
         * tx initiated as rider
         * current role is onwner
         * invalidRole
         */
        if (isCustomer && initatedAsRider && isHorseowner) {
            return acc;
        }

        return [...acc, tx];
    }, []);

    return response;
};

export const loadData = (params = {}) => (dispatch, getState, sdk) => {
    const { user } = getState();

    dispatch(fetchOrdersOrSalesRequest());

    return dispatch(fetchTransactionsData({ ...apiQueryParams, ...params }))
        .then(async response => {
            const txData = filterTxByRole(response, ensureCurrentUser(user.currentUser));

            dispatch(addMessages(response));

            return Promise.all(
                txData.data.data.map(async tx => {
                    const {
                        attributes: {
                            protectedData: { listingSubstitutionId },
                        },
                    } = tx;

                    if (listingSubstitutionId) {
                        const listing = await sdk.listings.query({
                            ids: [listingSubstitutionId],
                        });
                        dispatch(addMarketplaceEntities(listing));
                        const listingsSubstituted = listing.data.data[0];

                        tx.relationships.listing.data = listingsSubstituted;
                    }
                    return tx;
                })
            )
                .then(() => {
                    dispatch(addMarketplaceEntities(txData));
                    dispatch(fetchOrdersOrSalesSuccess(txData));
                    dispatch(fetchAppointments(txData.data.data));
                })
                .catch(e => {
                    throw new Error(e);
                });
        })
        .catch(e => {
            dispatch(fetchOrdersOrSalesError(storableError(e)));
            throw e;
        });
};

export const archieveChat = (txId, action = 'add') => (dispatch, getState, sdk) => {
    dispatch(requestArchievedTransactionData());

    const {
        user: { currentUser },
    } = getState();

    const {
        attributes: {
            profile: { protectedData },
        },
    } = currentUser;
    const isAddAction = action === 'add';
    const isRemoveAction = action === 'remove';

    const archievedTransactions = isAddAction
        ? {
              ...(protectedData.archievedTransactions || {}),
              [txId]: {
                  updatedAt: new Date().toString(),
              },
          }
        : {
              ...(protectedData.archievedTransactions || {}),
          };

    if (isRemoveAction) {
        delete archievedTransactions[txId];
    }

    const params = {
        protectedData: {
            archievedTransactions,
        },
    };

    const currentUserDataUpd = { ...currentUser };
    currentUserDataUpd.attributes.profile.protectedData.archievedTransactions = archievedTransactions;

    return dispatch(updateUserProfileInfo(params, currentUserDataUpd))
        .then(() => {
            dispatch(requestArchievedTransactionSuccess());
        })
        .catch(e => {
            dispatch(requestArchievedTransactionFailure(storableError(e)));
        });
};
