import React, { useState } from 'react';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';
import { trimDisplayNameLastWord } from '../../util/text';
import verified from './verified.svg';

import css from './Reviews.css';
// UNUSED ??
const Review = props => {
    const { review, intl } = props;
    const date = review.attributes.createdAt;
    const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

    return (
        <div className={css.review}>
            {review.type === 'externalReview' ? (
                <div className={css.rootAvater}>
                    <span className={css.initials}>{review.author.abbreviatedName}</span>
                </div>
            ) : (
                <Avatar className={css.avatar} user={review.author} />
            )}
            <div className={css.reviewWrapper}>
                <div className={css.reviewRatingWrapper}>
                    <ReviewRating
                        rating={review.attributes.rating}
                        className={css.reviewRating}
                        reviewStarClassName={css.reviewRatingStar}
                    />
                    {review.type !== 'externalReview' && (
                        <span className={css.verified}>
                            <img src={verified} /> <FormattedMessage id="Reviews.verified" />
                        </span>
                    )}
                </div>
                <p className={css.reviewContent}>{review.attributes.content}</p>
                <p className={css.reviewInfo}>
                    {review.type === 'externalReview' ? (
                        trimDisplayNameLastWord(review.author.displayName)
                    ) : (
                        <UserDisplayName user={review.author} intl={intl} shouldBeTrimmed />
                    )}
                    <span className={css.separator}>•</span>
                    {dateString}
                    <span className={css.desktopSeparator}></span>
                </p>
            </div>
        </div>
    );
};

Review.propTypes = {
    review: propTypes.review.isRequired,
    intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
    const { className, rootClassName, reviews = [], extReviews, intl } = props;
    const classes = classNames(rootClassName || css.root, className);
    let displayReviews = reviews;

    if (extReviews) {
        displayReviews = [...reviews, ...extReviews].sort((a, b) =>
            new Date(a.attributes.createdAt) > new Date(b.attributes.createdAt) ? -1 : 1
        );
    }

    const [numberOfReviewsToShow, setNumberOfReviewsToShow] = useState(3);

    const handleShowMore = function() {
        if (displayReviews.length > numberOfReviewsToShow + 3) {
            setNumberOfReviewsToShow(numberOfReviewsToShow + 3);
        } else {
            setNumberOfReviewsToShow(displayReviews.length);
        }
    };

    const shouldShowMoreButton = function() {
        if (displayReviews.length > numberOfReviewsToShow) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <ul className={classes}>
                {displayReviews.slice(0, numberOfReviewsToShow).map(r => {
                    return (
                        <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
                            <Review review={r} intl={intl} />
                        </li>
                    );
                })}
            </ul>
            {shouldShowMoreButton() && (
                <a onClick={handleShowMore} className={css.showMoreReview}>
                    + Mehr Bewertungen anzeigen
                </a>
            )}
        </>
    );
};

ReviewsComponent.propTypes = {
    className: string,
    rootCalssName: string,
    reviews: arrayOf(propTypes.review),

    // from injectIntl
    intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
