import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { Tooltip, IconSpinner, Button } from '../../../components';
import { ConditionalWrapper } from '../../../util/common';

import classNames from 'classnames';
import { addIncludedDataToListing } from '../helpers';
import { useListings } from '../../../hooks/useListings';
import config from '../../../config';

import css from './AcceptInquirySection.css';

const { userTypeRider, userTypeHorseowner } = config;

const AcceptInquirySection = ({
    userName,
    rootClassName,
    disabled,
    clickHandler,
    isSameUser,
    riderPage,
    currentUser,
    user,
    isLoggedOut,
    transactionDataInProgress,
}) => {
    const {
        id: { uuid: publicUserId },
        attributes: {
            profile: {
                publicData: { userType: publicUserType },
            },
        },
    } = user;

    const {
        id,
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = currentUser;

    const { uuid: currentUserId } = id || {};

    const ridersViews = currentUserType === userTypeRider && publicUserType === userTypeRider;
    const ownersViews =
        currentUserType === userTypeHorseowner && publicUserType === userTypeHorseowner;

    const riderToOwnerPage = currentUserType === userTypeRider && !riderPage;
    const ownerToRiderPage = currentUserType === userTypeHorseowner && riderPage;

    const authorId =
        isSameUser || ridersViews
            ? null
            : riderToOwnerPage
            ? publicUserId
            : ownerToRiderPage
            ? currentUserId
            : null;

    const [listings, horseownerListingsInProgress, included] = useListings({
        params: {
            authorId,
        },
        allowed: !!authorId,
    });

    const horseownerListings =
        listings && included ? addIncludedDataToListing(listings, included) : null;

    const noListingsForOwner =
        !ownersViews &&
        ((horseownerListings && horseownerListings.length === 0) || !horseownerListings);

    const inquiryDisabled = isLoggedOut
        ? false
        : (disabled && !isSameUser) || noListingsForOwner || ridersViews || ownersViews;

    const resolveInquiryDisabledKey = () => {
        return (
            (!ridersViews && noListingsForOwner && 'noListingsForOwner') ||
            (inquiryDisabled && 'inquiryDisabled')
        );
    };

    return horseownerListingsInProgress || transactionDataInProgress ? (
        <IconSpinner />
    ) : (
        <aside
            className={classNames({
                [css.acceptInquirySection]: true,
                [rootClassName]: !!rootClassName,
            })}
        >
            {!disabled && (
                <p>
                    <FormattedMessage
                        id={
                            isSameUser
                                ? 'ProfileSettingsPage.acceptRejectInfoSameUser'
                                : 'ProfileSettingsPage.acceptRejectInfo'
                        }
                        values={{ userName }}
                    />
                </p>
            )}
            <ConditionalWrapper
                condition={inquiryDisabled && !isSameUser}
                wrapper={children => (
                    <Tooltip
                        position="top"
                        tooltipClassName={css.inquiryDisabledTooltip}
                        content={
                            <div>
                                <p>
                                    <FormattedMessage
                                        id={`ProfileSettingsPage.action-${resolveInquiryDisabledKey()}-heading`}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id={`ProfileSettingsPage.action-${resolveInquiryDisabledKey()}-desc`}
                                        values={{ userName }}
                                    />
                                </p>
                            </div>
                        }
                    >
                        {children}
                    </Tooltip>
                )}
            >
                <Button
                    type="button"
                    disabled={
                        isLoggedOut
                            ? false
                            : disabled ||
                              isSameUser ||
                              noListingsForOwner ||
                              ridersViews ||
                              ownersViews
                    }
                    onClick={clickHandler}
                >
                    <FormattedMessage id="EditUserMainInfoSection.submitButtonText" />
                    {isSameUser}
                </Button>
            </ConditionalWrapper>
        </aside>
    );
};

export default AcceptInquirySection;
