import React, { useState } from 'react';
import { Slide } from 'pure-react-carousel';
import classNames from 'classnames';

import Carousel from '../../components/ListingCardsCarousel/Carousel';
import { IconSpinner } from '../../components';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useReviews } from '../../hooks/useReviews';

import { ReviewItem } from '../LandingPage/sections/SectionCommunityFeedbacks/ReviewItem';
import { ReviewsSummary } from '../LandingPage/sections/SectionCommunityFeedbacks/ReviewsSummary';
import css from './AuthenticationPage.css';

export const ReviewsWidget = props => {
    const {
        className,
        name
    } = props;
    const [isMobile, , computing] = useIsMobile(768);
    const [previewed, setPreviewed] = useState(false);

    const [reviewsData, loading, error] = useReviews({
        allowed: !computing,
        perPage: isMobile ? 10 : 26,
    });

    const { review_count: reviewCount, average_rating: averageRating, reviews } = reviewsData;

    if (error || !Array.isArray(reviews) || reviews.length === 0) return null;
    if (loading) return <IconSpinner />;

    // const schemaData = {
    //     "@context": "https://schema.org",
    //     "@type": "Organization",
    //     "name": name || "HorseDeal | Die Sharing-Plattform für Pferde",
    //     "address": {
    //         "@type": "PostalAddress",
    //         "streetAddress": "Bösch 80A",
    //         "addressLocality": "Hünenberg",
    //         "addressRegion": "ZG",
    //         "postalCode": "6331",
    //         "addressCountry": "CH"
    //     },
    //     "telephone": "+41 800 444 550",
    //     "aggregateRating": {
    //         "@type": "AggregateRating",
    //         "ratingValue": averageRating,
    //         "reviewCount": reviewCount
    //     },
    //     "review": reviews.map(review => ({
    //         "@type": "Review",
    //         "author": {
    //             "@type": "Person",
    //             "name": review.author.name.replace(/\t/g, ' ')
    //         },
    //         "reviewRating": {
    //             "@type": "Rating",
    //             "ratingValue": review.rating
    //         },
    //         "reviewBody": review.comments,
    //         "datePublished": new Date(review.date_created).toISOString()
    //     }))
    // };

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": name || "HorseDeal | Die Sharing-Plattform für Pferde",
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": averageRating,
            "bestRating": 5,
            "reviewCount": reviewCount
        }
    };

    return (
        <div className={className}>
            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
            <ReviewsSummary averageRating={averageRating} reviewCount={reviewCount} />
            {!isMobile && (
                <div
                    className={classNames(css.carousel, {
                        [css.previewed]: previewed,
                    })}
                    onMouseOver={() => setPreviewed(true)}
                    onMouseLeave={() => setPreviewed(false)}
                >
                    <div>
                        {reviews.map((item, index) => (
                            <ReviewItem key={index} {...item} />
                        ))}
                    </div>
                    <div>
                        {reviews.map((item, index) => (
                            <ReviewItem key={index} {...item} />
                        ))}
                    </div>
                </div>
            )}
            {isMobile && (
                <Carousel
                    isMobile={isMobile}
                    items={reviews}
                    minItemsCount={1}
                    sliderClassName={classNames(css.authSliderContainer, {
                        [css.mobSliderClassName]: isMobile,
                    })}
                    showPrevButton={isMobile}
                    showNextButton={isMobile}
                    showDots={isMobile}
                    sliderVisibleSlides={1}
                    rendererSingleItem={() => null}
                    rendererCarousel={(item, index) => (
                        <Slide index={index} key={index}>
                            <ReviewItem {...item} isMobile />
                        </Slide>
                    )}
                />
            )}
        </div>
    );
};
