import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';

import { propTypes } from '../../util/types';
import {
    resolveFormattedTotalPrice,
    calculateListingTotalPrice,
    calculateCouponDiscount,
    converPriceToSdkMoney,
    PERCENT_OFF_TYPE,
    AMOUNT_OFF_FROM_TOTAL_TYPE,
} from './helpers';
import css from './BookingBreakdown.css';
import config from '../../config';

const PLATFORM_COMMISSION = config.platformCommission;

const LineItemUnitPrice = props => {
    const { transaction, isProvider, intl } = props;
    const { attributes } = transaction || {};
    const { payinTotal } = attributes || {};

    /** initial price of the listing */
    const totalPrice = calculateListingTotalPrice(payinTotal);

    let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';

    const resolveTransactionTotalPrice = () => {
        const { couponLineItemData } = props;
        const { valid, percent_off, amount_off } = couponLineItemData || {};
        const applyCouponMaybe = valid && !isProvider;

        const commission = Number((totalPrice * PLATFORM_COMMISSION) / 100) / 100;

        const applyCommission = amount => (isProvider ? amount - commission : amount + commission);

        const numericTxPrice = totalPrice / 100;
        if (applyCouponMaybe) {
            const numericTxPriceWithCouponApplied = percent_off
                ? calculateCouponDiscount(PERCENT_OFF_TYPE, numericTxPrice, percent_off)
                : calculateCouponDiscount(AMOUNT_OFF_FROM_TOTAL_TYPE, numericTxPrice, amount_off);

            const totalPriceWithCouponApplied = converPriceToSdkMoney(
                applyCommission(numericTxPriceWithCouponApplied)
            );
            return resolveFormattedTotalPrice(totalPriceWithCouponApplied, intl);
        } else {
            const totalPriceWithoutCoupon = converPriceToSdkMoney(applyCommission(numericTxPrice));
            return resolveFormattedTotalPrice(totalPriceWithoutCoupon, intl);
        }
    };

    const totalLabel = isProvider ? (
        <FormattedMessage id={providerTotalMessageId} />
    ) : (
        <FormattedMessage id="BookingBreakdown.total" />
    );

    const txTotalSum = resolveTransactionTotalPrice();

    return (
        <>
            <hr className={css.totalDivider} />
            <div className={css.lineItemTotal}>
                <div className={css.totalLabel}>{totalLabel}</div>
                <div className={css.totalPrice}>{txTotalSum}</div>
            </div>
        </>
    );
};

LineItemUnitPrice.propTypes = {
    transaction: propTypes.transaction.isRequired,
    isProvider: bool.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
