import { CREDITS_PACKAGE_1, CREDITS_PACKAGE_2, CREDITS_PACKAGE_3 } from '../credits-config';
import {
    appStoreIAPProductsDictionary,
    LISTING_PACKAGE_ANONYM_LISTING_ID,
    LISTING_PACKAGE_SOCIAL_BOOST_ID,
} from '../marketplace-custom-config';
import { initUsercentrics } from './scripts';
import { checkIOS } from './userAgent';

export const adaptIOSFormField = ({ disabled }) => {
    const { iOS, safari } = checkIOS();
    if (!iOS && !safari) return {};

    if (disabled) {
        return {
            disabled: false,
            readOnly: 'readOnly',
            onFocus: e => e,
            onBlur: e => e,
            tabindex: '-1',
            style: { pointerEvents: 'none' },
        };
    }
    return {};
};
/**
 * Get marketplace product id from in app purchase product id
 *
 * @param {string} productId - in app purchase product id
 * @returns
 */
export const getMarketplaceProductId = productId => {
    /** e.g. { consumable_credits_30: p_c_30 } */
    const IAPtoMarketplaceIdsDictionary = Object.entries(appStoreIAPProductsDictionary).reduce(
        (acc, [key, value]) => ({ ...acc, [value]: key }),
        {}
    );

    return IAPtoMarketplaceIdsDictionary[productId]; // e.g.'p_c_30'
};
/**
 * Handle credits purchase for native ios app
 *
 * @param {*} packageId - CREDITS_PACKAGE_1,2,3
 * @returns void
 */
export const postIAPMessage = (packageId, onError = () => null) => {
    const iapProductId = appStoreIAPProductsDictionary[packageId];

    if (!iapProductId) return onError();

    window.ReactNativeWebView.postMessage(iapProductId);
};
/**
 * For IOS, WebView only
 * @param {object} e - browser event
 */
export const addIOSWVGlobalListeners = e => {
    try {
        const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : {};

        if (messageData && String(messageData.isTracking) === 'true') {
            /**
             * Initiate usercentrics only when a user clicks the "Allow"
             * button in the ios Allow Tracking modal window
             */
            initUsercentrics();
        }
    } catch (e) {
        // to do
    }
};
/**
 * Check if event comes from WebView and holds
 * data needed for In app purchase
 *
 * @param {object} e - browser event
 */
export const checkIAPTransactionMessage = e => {
    try {
        const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : {};

        if (messageData && messageData.transactionId && messageData.productId) {
            return messageData;
        }

        if (messageData && messageData.purchaseFailed) {
            return {
                error: true,
                reason: messageData.purchaseFailed,
            };
        }

        return null;
    } catch (e) {
        // to do
    }
};

export const checkIfCreditsProduct = id =>
    id === CREDITS_PACKAGE_1 || id === CREDITS_PACKAGE_2 || id === CREDITS_PACKAGE_3;

export const checkIfHorsePackagesProduct = id =>
    id === LISTING_PACKAGE_SOCIAL_BOOST_ID || id === LISTING_PACKAGE_ANONYM_LISTING_ID;
