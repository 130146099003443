import React from 'react';
import css from './ShareViaSocialNetworkButtons.css';

const whatsapp = 'https://web.whatsapp.com/send?text';
const whatsappMob = 'whatsapp://send?text';

const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="3 2 17 17" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6 5.3C16.1 3.8 14.1 3 12 3C7.6 3 4 6.6 4 11C4 12.4 4.40001 13.8 5.10001 15L4 19L8.20001 17.9C9.40001 18.5 10.7 18.9 12 18.9C16.4 18.9 20 15.3 20 10.9C20 8.8 19.1 6.8 17.6 5.3ZM12 17.6C10.8 17.6 9.60001 17.3 8.60001 16.7L8.39999 16.6L5.89999 17.3L6.60001 14.9L6.39999 14.6C5.69999 13.5 5.39999 12.3 5.39999 11.1C5.39999 7.5 8.4 4.5 12 4.5C13.8 4.5 15.4 5.2 16.7 6.4C18 7.7 18.6 9.3 18.6 11.1C18.6 14.6 15.7 17.6 12 17.6ZM15.6 12.6C15.4 12.5 14.4 12 14.2 12C14 11.9 13.9 11.9 13.8 12.1C13.7 12.3 13.3 12.7 13.2 12.9C13.1 13 13 13 12.8 13C12.6 12.9 12 12.7 11.2 12C10.6 11.5 10.2 10.8 10.1 10.6C10 10.4 10.1 10.3 10.2 10.2C10.3 10.1 10.4 10 10.5 9.9C10.6 9.8 10.6 9.7 10.7 9.6C10.8 9.5 10.7 9.4 10.7 9.3C10.7 9.2 10.3 8.2 10.1 7.8C10 7.5 9.80001 7.5 9.70001 7.5C9.60001 7.5 9.49999 7.5 9.29999 7.5C9.19999 7.5 8.99999 7.5 8.79999 7.7C8.59999 7.9 8.10001 8.4 8.10001 9.4C8.10001 10.4 8.79999 11.3 8.89999 11.5C8.99999 11.6 10.3 13.7 12.3 14.5C14 15.2 14.3 15 14.7 15C15.1 15 15.9 14.5 16 14.1C16.2 13.6 16.2 13.2 16.1 13.2C16 12.7 15.8 12.7 15.6 12.6Z"
            fill="#1A2B49"
        />
    </svg>
);

export default ({ url = '', title = '', text = '' }) => {
    const inBrowser = typeof window !== 'undefined';
    const href =
        inBrowser && window.navigator.userAgent.match(/iPhone|Android/i)
            ? `${whatsappMob}=${text} ${url}`
            : `${whatsapp}=${text} ${url}`;
    return (
        <a href={href} target="_blank" className={css.shareItem} title={title}>
            {icon}
            <span>Teilen auf WhatsApp</span>
        </a>
    );
};
